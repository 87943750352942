import docReady from 'doc-ready'

import {addHandler, windowScroll, windowSize} from '../util/scroll_resize'
import {initContainers} from '../util/init'
import {updateLinkState} from '../util/link_state'
import {lazyLoad, toggle} from '../util/tools'
import {PHONE_MAX} from './constants'

// assign these for faster calculations
const max = Math.max
const min = Math.min

export let cursor
export let currentPattern = null
export let currentPatternClass = 'current-pattern'
export let currentCoords = {
  'x': null,
  'y': null
}

docReady(() => {
  let setup = new SiteSetup()
  let init = new SiteInit()
  window.setTimeout(() => {
    // first up reveal (js only)
    document.body.style.opacity = null
    document.documentElement.classList.add('state-initialised')
  }, 100)
})

class SiteSetup {
  constructor () {
    this.windowWidth = null
    this.windowHeight = null

    addHandler({
      resize: (w, h) => {
        this.windowWidth = w
        this.windowHeight = h
      },
      scroll: (t, l) => {}
    }, true)

    // init
    this.initCoordListener()
    this.initPatterns()
    this.initScrollHack()
    if (!window.Modernizr.touchevents) {
      this.initFakeCursor()
    }
  }
  /**
  * 1. for each click show/hide a pattern
  *    if we show a pattern show a new one each time
  * 2. 404 pattern is handled seperately atm (no animation)
  *
  */
  initPatterns () {
    // 1. patterns
    const patterns = document.querySelectorAll('[class^=pattern-]:not(.pattern-404)')
    let patternData = [] // currently just local, may need to move.?
    const sequenceTotal = 4
    let sequenceCurrent = sequenceTotal

    const initFirstPattern = (pData) => {
      currentPattern = pData
      this.animatePatterns()
      document.body.classList.add('state-pattern-visible')
    }
    const setPatternData = (pattern) => {
      let pData = {
        'pattern': pattern,
        'letters': []
      }
      pattern.querySelectorAll('[class^=letter-]').forEach((l) => {
        pData.letters.push({
          'letter': l,
          'axis': l.getAttribute('data-track-axis'),
          'attribute': l.getAttribute('data-track-attribute'),
          'start': parseInt(l.getAttribute('data-track-start')),
          'current': parseInt(l.getAttribute('data-track-start')),
          'reverse': l.getAttribute('data-track-reverse') === 'true',
          'rewind': l.getAttribute('data-track-rewind') === 'true'
        })
      })
      patternData.push(pData)
    }
    const handleState = () => {
      const isPattern = sequenceCurrent !== sequenceTotal
      // update currentPattern
      if (isPattern) {
        document.body.classList.add('state-pattern-visible')
        updateCurrentPattern()
      } else {
        document.body.classList.remove('state-pattern-visible')
        // scroll init (to architects) desktop only
        const firstFigure = document.querySelector('.projects-content .page-items:first-child .page-item:first-child')
        const shift = parseInt(window.getComputedStyle(firstFigure).paddingTop) - (this.windowHeight * 0.5)
        if (windowScroll().top < 200 && this.windowWidth > PHONE_MAX) {
          window.setTimeout(() => {
            window.scroll({
              top: shift,
              left: 0,
              behavior: 'smooth'
            })
          }, 600)
        }
      }
      sequenceCurrent = isPattern ? sequenceCurrent + 1 : 0
    }
    const updateCurrentPattern = () => {
      const previousIndex = patternData.indexOf(currentPattern)
      const nextIndex = patternData.length - 1 >= previousIndex + 1 ? previousIndex + 1 : 0
      const previousPattern = currentPattern
      const nextPattern = patternData[nextIndex]

      for (var i = 0; i < nextPattern.letters.length; i++) {
        // reset all the letters
        const l = currentPattern.letters[i]
        l.current = currentCoords[l.axis] > 0.5 ? l.start : 100 - l.start
      }

      currentPattern = nextPattern

      previousPattern.pattern.classList.remove(currentPatternClass)
      nextPattern.pattern.classList.add(currentPatternClass)
    }

    // bind click
    patterns.forEach((p, i) => {
      setPatternData(p)
      if (i === 0) {
        initFirstPattern(patternData[i])
      }
      p.addEventListener('click', (e) => {
        handleState()
      })
    })

    // 2. 404
    // bind click (go home)
    const fourOhFour = document.querySelector('.pattern-404')
    if (fourOhFour) {
      fourOhFour.addEventListener('click', (e) => {
        window.location = '/'
      })
    }
  }
  /**
  * detects if we have an accelerometer or a mouse,
  * if so we bind a listener that tracks and updates
  * the 'global' variable currentCoords (as a decimal between 0 and 1)
  *
  */
  initCoordListener () {
    const boundary = 20
    const boundaryDoubled = 40
    // landscape / portrait switch these
    let xListener = 'gamma'
    let yListener = 'beta'
    // what the uses tilt is at start
    let originalTiltX = 0
    let originalTiltY = 25

    let updateOriginalTilt = false

    const trackMouseMove = (e) => {
      const x = (e.x / this.windowWidth)
      const y = (e.y / this.windowHeight)
      currentCoords.x = x
      currentCoords.y = y
      if (cursor) {
        cursor.style.top = `${e.y}px`
        cursor.style.left = `${e.x}px`
      }
    }

    const setOriginalTilt = (e) => {
      originalTiltX = e[xListener]
      originalTiltY = e[yListener]

      updateOriginalTilt = false
    }

    const trackDeviceTilt = (e) => {
      if (updateOriginalTilt) {
        setOriginalTilt(e)
      }

      // x -45 to 45
      // y -20 to 70 (adds a slight natural tilt)
      const tiltX = max(min((e[xListener] - originalTiltX), boundary), -boundary)
      const tiltY = max(min((e[yListener] - originalTiltY), boundary), -boundary)
      const x = ((tiltX + boundary) / boundaryDoubled)
      const y = ((tiltY + boundary) / boundaryDoubled)
      currentCoords.x = x
      currentCoords.y = y
    }

    // add suitable listeners
    if ('MouseEvent' in window) {
      // we're assuming we have a mouse
      document.body.addEventListener('mousemove', trackMouseMove)
    }
    if ('DeviceOrientationEvent' in window) {
      // we have an accelerometer (welllll not really accurate)
      window.addEventListener('deviceorientation', trackDeviceTilt)

      // if we move the accelerometer - kill the mouse listener
      // tried doing it the oposite way but a click fires the mouse move event
      // on a touch
      window.addEventListener('deviceorientation', (e) => {
        document.body.removeEventListener('mousemove', trackMouseMove)
      }, {'once': true})
    }

    addHandler({
      resize: (w, h) => {
        if (w < h) {
          // portrait
          xListener = 'gamma'
          yListener = 'beta'
        } else {
          // landscape
          xListener = 'beta'
          yListener = 'gamma'
        }

        updateOriginalTilt = true
      },
      scroll: (t, l) => {}
    }, true)
  }
  /**
  * animate the currentPattern according to the currentCoords
  * each animation can have a unique axis/attribute/start/reverse
  * (set as data attributes of letter)
  *
  */
  animatePatterns () {
    let frameQueued = false
    const sin = Math.sin
    const PI = Math.PI
    const speed = 2 // multiplier
    const round = (r, m = 1) => { return Math.round(r * m) / m }

    const updateFrame = () => {
      if (!frameQueued) {
        frameQueued = true
        // loop all letters and update the requested attribute
        for (var i = 0; i < currentPattern.letters.length; i++) {
          let computedValue
          const l = currentPattern.letters[i]
          const difference = currentCoords[l.axis] - (l.current / 100)
          const value = round(l.current + (sin(PI * difference) * speed), 100)

          computedValue = !l.reverse ? value : 100 - value
          if (l.rewind) {
            if (computedValue > 50) {
              // rewind (backwards)
              computedValue = 200 - (computedValue * 2)
              l.letter.classList.add('rewind')
            } else {
              // play (normal)
              computedValue = (computedValue * 2)
              l.letter.classList.remove('rewind')
            }
          }

          l.letter.style[l.attribute] = computedValue + '%'
          // don't reverse the current value (only apply that to the DOM)
          l.current = value
        }
        frameQueued = false
      }
      window.requestAnimationFrame(updateFrame)
    }
    // init (width a short tineout)
    window.setTimeout(function () {
      window.requestAnimationFrame(updateFrame)
    }, 1000)
  }
  /**
  * create a fake cursor that tracks to the movements of the mouse
  * if we're hovering a link add a class to the body
  *
  */
  initFakeCursor () {
    document.documentElement.classList.add('fake-cursor')
    cursor = document.createElement('div')
    cursor.classList.add('cursor')
    document.body.append(cursor)

    document.querySelectorAll('a').forEach((a) => {
      a.addEventListener('mouseenter', (e) => {
        document.body.classList.add('state-cursor-hovering')
      })
      a.addEventListener('mouseleave', (e) => {
        document.body.classList.remove('state-cursor-hovering')
      })
    })
    // if we go out of the window hide the cursor
    document.addEventListener('mouseout', (e) => {
      e = e ? e : window.event
      const from = e.relatedTarget || e.toElement
      if (!from || from.nodeName === 'HTML') {
        document.body.classList.add('state-cursor-exit')
      }
    })
    document.addEventListener('mouseover', (e) => {
      document.body.classList.remove('state-cursor-exit')
    })
  }
  /**
  * ios has the toolbar that resets the browser height
  * with this we increase/descrease the size of the pattern block
  *
  */
  initScrollHack () {
    let enableHack
    let initHeight = null
    let initwindowWidth = null
    let maxHeight = null
    let patterns = document.querySelectorAll('[class^=pattern-]:not(.pattern-404)')
    let distance = 0 // scroll distance
    let pt = 0 // previous top (scroll)

    if (patterns.length === 0) { return }

    const setInitHeights = () => {
      clearHeights()
      // get the 'natural' size and apply to style
      initHeight = parseInt(window.getComputedStyle(patterns[0]).height)
      patterns.forEach((p, i) => {
        p.style.height = `${initHeight}px`
      })
    }

    const clearHeights = () => {
      patterns.forEach((p, i) => {
        p.style.height = null
      })
    }

    addHandler({
      resize: (w, h) => {
        if (w <= PHONE_MAX) {
          // only init this once or if we change the browsers width (orientation change)
          if (!initHeight || initwindowWidth !== w) {
            // init
            setInitHeights()
            initwindowWidth = w
            enableHack = true
          }
          // this value matches the css for element including 'inset/margin'
          maxHeight = (this.windowHeight * 0.98) + (this.windowWidth * 0.02)
        } else {
          clearHeights()
          enableHack = false
        }
      },
      scroll: (t, l) => {
        if (enableHack && initHeight) {
          // if we're going back up, just do a quick switch back
          distance = (t > pt) && (t > 0) ? (distance + (t - pt)) : 0
          var shift = max(min((distance + initHeight), maxHeight), initHeight)
          patterns.forEach((p, i) => {
            p.style.height = `${shift}px`
          })
        }

        pt = t
      }
    }, true)
  }
}

class SiteInit {
  // initialises newly loaded content
  // recallable for ajax loaded content
  constructor () {
    const current = document.querySelectorAll('#content > article')
    initContainers(current)
    updateLinkState(document.querySelectorAll('#header, #footer, #content'))

    // load lazy images
    current[0].querySelectorAll('.lazyload').forEach((el) => {
      lazyLoad(el)
    })
  }
}
